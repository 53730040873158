// src/components/Projects/ProjectCard.jsx
import React from 'react';
import { ChevronRight } from 'lucide-react';

const ProjectCard = ({ project, onClick, isFocused, index }) => {
  const cardRef = React.useRef(null);

  React.useEffect(() => {
    if (isFocused && cardRef.current) {
      cardRef.current.focus();
    }
  }, [isFocused]);

  const handleClick = () => {
    window.gtag('event', 'project_view', {
      'event_category': 'Portfolio',
      'event_label': `${project.title} - ${project.company}`,
      'value': index
    });
    onClick(project);
  };

  return (
    <div
      role="listitem"
      className="flex"
    >
      <button
        ref={cardRef}
        className={`w-full bg-slate-900 rounded-lg shadow-lg p-6 text-left transition-colors group border
          ${isFocused
            ? 'border-indigo-400 ring-2 ring-indigo-400'
            : 'border-slate-800 hover:bg-slate-800'
          }`}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick();
          }
        }}
        tabIndex={0}
      >
        <div className="mb-4 flex justify-between items-center">
          {project.icon}
          <ChevronRight
            className={`w-5 h-5 transition-colors ${
              isFocused
                ? 'text-indigo-400'
                : 'text-slate-400 group-hover:text-indigo-400'
            }`}
            aria-hidden="true"
          />
        </div>
        <h3 className="text-lg font-semibold text-white mb-2">{project.title}</h3>
        <p className="text-sm text-indigo-400 mb-3">{project.company}</p>
        <p className="text-slate-300">{project.description}</p>
      </button>
    </div>
  );
};

export default ProjectCard;