// src/components/Projects/ProjectDrawer.jsx
import React, { useRef, useEffect } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ExampleContent = ({ example, index }) => (
  <motion.div
    variants={{
      closed: { opacity: 0, x: 20 },
      open: { opacity: 1, x: 0 }
    }}
    className="border-t border-slate-800 pt-8 first:border-t-0 first:pt-0"
  >
    <h4 className="text-xl font-semibold mb-4 text-white">{example.title}</h4>
    {example.isVideo ? (
      <div className="mb-4">
        <div 
          className="relative w-full rounded-lg shadow-xl border border-slate-700 overflow-hidden" 
          style={{ paddingBottom: '56.25%' }}
        >
          <iframe
            src={example.videoUrl}
            className="absolute top-0 left-0 w-full h-full"
            frameBorder="0"
            scrolling="no"
            title={`${example.title} video`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    ) : (
      <motion.img 
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3 + (index * 0.1) }}
        src={example.image} 
        alt={example.title}
        className="rounded-lg shadow-xl w-full mb-4 border border-slate-700"
      />
    )}
    <p className="text-slate-300 mb-4">{example.description}</p>
    <ul className="space-y-2">
      {example.details.map((detail, idx) => (
        <motion.li
          key={idx}
          variants={{
            closed: { opacity: 0, x: 20 },
            open: { opacity: 1, x: 0 }
          }}
          className="flex items-center text-slate-300"
        >
          <div className="w-2 h-2 bg-indigo-400 rounded-full mr-2" aria-hidden="true"></div>
          {detail}
        </motion.li>
      ))}
    </ul>
  </motion.div>
);

const ProjectDrawer = ({ project, isOpen, onClose }) => {
  const drawerRef = useRef(null);
  const closeButtonRef = useRef(null);

  // Handle focus management
  useEffect(() => {
    if (isOpen && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [isOpen]);

  // Handle focus trap and keyboard navigation
  useEffect(() => {
    if (!isOpen) return;

    const handleTabKey = (e) => {
      if (e.key !== 'Tab') return;

      const focusableElements = drawerRef.current?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (!focusableElements?.length) return;
      
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey) {
        if (document.activeElement === firstElement) {
          lastElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastElement) {
          firstElement.focus();
          e.preventDefault();
        }
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
      handleTabKey(e);
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  if (!project) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={onClose}
            aria-hidden="true"
          />
          <motion.div
            ref={drawerRef}
            role="dialog"
            aria-modal="true"
            aria-labelledby="drawer-title"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed inset-y-0 right-0 w-full md:w-2/3 lg:w-3/4 bg-slate-900 shadow-xl z-50 border-l border-slate-800"
          >
            <div className="h-full overflow-y-auto">
              <motion.div 
                className="p-6"
                initial={{ opacity: 0, x: 20 }}
                animate={{ 
                  opacity: 1, 
                  x: 0,
                  transition: { 
                    delay: 0.2,
                    staggerChildren: 0.1 
                  }
                }}
              >
                <motion.div 
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="flex justify-between items-start mb-4"
                >
                  <div>
                    <h3 id="drawer-title" className="text-2xl font-bold text-white">
                      {project.title}
                    </h3>
                    <p className="text-indigo-400">{project.company}</p>
                  </div>
                  <button 
                    ref={closeButtonRef}
                    onClick={onClose} 
                    className="text-slate-400 hover:text-slate-300 p-2 rounded-lg hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                    aria-label="Close project details"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </motion.div>
                
                <motion.p 
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="text-slate-300 mb-8"
                >
                  {project.description}
                </motion.p>
                
                <div className="space-y-8">
                  {project.examples.map((example, index) => (
                    <ExampleContent key={index} example={example} index={index} />
                  ))}
                </div>
              </motion.div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ProjectDrawer;