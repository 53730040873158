// src/components/Skills/SkillsSection.jsx
import React from 'react';
import SkillCard from './SkillCard';
import { Users, Code, LineChart, Layout } from 'lucide-react';

const skills = [
  { name: "UX Design", icon: <Users className="w-5 h-5" /> },
  { name: "User Flow Development", icon: <Code className="w-5 h-5" /> },
  { name: "A/B Testing & Analytics", icon: <LineChart className="w-5 h-5" /> },
  { name: "Design Systems", icon: <Layout className="w-5 h-5" /> }
];

const SkillsSection = () => {
  return (
    <section aria-labelledby="skills-heading" className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h2 id="skills-heading" className="text-2xl font-bold text-white mb-8">Core Skills</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {skills.map((skill) => (
          <SkillCard key={skill.name} {...skill} />
        ))}
      </div>
    </section>
  );
};

export default SkillsSection;