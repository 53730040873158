// src/App.jsx
import React, { useState, useEffect } from 'react';  // Added useEffect import
import GoogleAnalytics from './components/Analytics/GoogleAnalytics';
import Header from './components/Layout/Header';
import SkillsSection from './components/Skills/SkillsSection';
import ProjectsSection from './components/Projects/ProjectsSection';
import ProjectDrawer from './components/Projects/ProjectDrawer';
import Footer from './components/Layout/Footer';

// Import projects data from ProjectsSection or create a shared constant
import { projects } from './components/Projects/ProjectsSection';  // You'll need to export this from ProjectsSection

const App = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [focusedProjectIndex, setFocusedProjectIndex] = useState(-1);  // Added this state

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setTimeout(() => setSelectedProject(null), 300);
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (openDrawer) {
        if (e.key === 'Escape') {
          handleDrawerClose();
        }
      } else {
        if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
          e.preventDefault();
          setFocusedProjectIndex(prev => {
            const direction = e.key === 'ArrowRight' ? 1 : -1;
            const newIndex = prev + direction;
            const maxIndex = projects.length - 1;
            
            if (newIndex < 0) return maxIndex;
            if (newIndex > maxIndex) return 0;
            return newIndex;
          });
        } else if (e.key === 'Enter') {
          // Check focusedProjectIndex inside the handler using a ref
          if (focusedProjectIndex !== -1) {
            handleProjectSelect(projects[focusedProjectIndex]);
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [openDrawer, handleProjectSelect, focusedProjectIndex]); // Added focusedProjectIndex to dependencies


  return (
    <>
      <div className="min-h-screen bg-slate-950">
      <GoogleAnalytics />
      <a href="#main" className="sr-only focus:not-sr-only focus:absolute focus:p-4 focus:bg-indigo-600 focus:text-white">
          Skip to main content
        </a>
        <Header />
        <main id="main" role="main">
        <SkillsSection />
        <ProjectsSection 
          onProjectSelect={handleProjectSelect} 
          focusedProjectIndex={focusedProjectIndex}  // Pass this to ProjectsSection
        />
        </main>
        <Footer />
        <ProjectDrawer
          project={selectedProject}
          isOpen={openDrawer}
          onClose={handleDrawerClose}
        />
      </div>
    </>
  );
};

export default App;