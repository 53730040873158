// src/components/Layout/Header.jsx
import React from 'react';

const Header = () => {
  return (
    <header role="banner" className="bg-slate-900 border-b border-slate-800">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row md:items-center md:space-x-8">
          <div className="mb-6 md:mb-0">
            <div className="w-48 h-48 rounded-full overflow-hidden border-4 border-indigo-400 shadow-xl">
              <img
                src="/images/linkedin-pic.jpg"
                alt="Kenneth Rokh"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div>
            <h1 className="text-4xl font-bold text-white">Kenneth Rokh</h1>
            <p className="mt-2 text-xl text-slate-300">Senior UX/Product Designer</p>
            <p className="mt-4 max-w-2xl text-slate-400">
              Crafting elegant digital experiences that transform complex technical systems into intuitive products, focusing on user flows, interaction patterns, user testing, and meaningful interactions.
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;