// src/components/Layout/Footer.jsx
import React from 'react';
import { Briefcase } from 'lucide-react';

const Footer = () => {
  return (
    <footer role="contentinfo" className="bg-slate-900 border-t border-slate-800 mt-12">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-2xl font-bold text-white mb-4">Let's Connect</h2>
        <p className="text-slate-300">
          <a 
            href="https://linkedin.com/in/kenneth-rokh" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-indigo-400 hover:text-indigo-300 transition-colors flex items-center justify-center space-x-2"
          >
            <Briefcase className="w-5 h-5" />
            <span>On LinkedIn</span>
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;