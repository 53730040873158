// src/components/Skills/SkillCard.jsx
import React from 'react';

const SkillCard = ({ name, icon }) => {
  return (
    <div className="bg-slate-900 p-4 rounded-lg shadow-lg flex items-center space-x-3 border border-slate-800">
      <div className="text-indigo-400">{icon}</div>
      <span className="text-slate-300">{name}</span>
    </div>
  );
};

export default SkillCard;